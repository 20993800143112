import { api } from './index';

export const getMeetingInfo = (activityId) => {
  return api.request({
    method: 'get',
    url: `/activities/${activityId}/meeting-info`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
