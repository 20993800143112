import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { Warning } from '../../components/Warning';
import { Loader } from '../../components/Loader';

import { getMeetingInfo } from '../../services/api/activity';
import { getMe } from '../../services/api/user';

import './styles.module.css';

declare var ZoomMtg;

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.2.0/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

ZoomMtg.i18n.load('pt-PT');
ZoomMtg.i18n.reload('pt-PT');

export const Zoom = () => {
  const { eventId, activityId } = useParams();
  const { initialized, keycloak } = useKeycloak();

  const [status, setStatus] = useState('loading');

  const startMeeting = (
    signature,
    meetingNumber,
    meetingPassword,
    userName,
    userEmail,
  ) => {
    document.getElementById('zmmtg-root').style.display = 'block';

    const apiKey = process.env.REACT_APP_ZOOM_API_KEY;
    const cienteStudioUrl = process.env.REACT_APP_CIENTE_STUDIO_URL;

    const leaveUrl = `${cienteStudioUrl}/hotsite-private/${eventId}/activities/${activityId}`;

    ZoomMtg.init({
      leaveUrl,
      success: () => {
        ZoomMtg.join({
          signature,
          meetingNumber,
          userName,
          apiKey,
          userEmail,
          passWord: meetingPassword,
          error: () => {
            setStatus('error');
          },
        });
      },
      error: () => {
        setStatus('error');
      },
    });
  };

  const fetchSignature = async (userName, userEmail) => {
    try {
      const {
        data: { data },
      } = await getMeetingInfo(activityId);

      const { meeting_signature, meeting_number, meeting_password } = data;

      startMeeting(
        meeting_signature,
        meeting_number,
        meeting_password,
        userName,
        userEmail,
      );

      setStatus('success');
    } catch {
      setStatus('error');
    }
  };

  const fetchMe = async () => {
    if (keycloak?.token) {
      try {
        const {
          data: { data },
        } = await getMe();

        const { user } = data;

        fetchSignature(user.name, user.email);
      } catch {
        setStatus('error');
      }
    } else {
      setStatus('unauthorized');
    }
  };

  useEffect(() => {
    if (initialized) {
      fetchMe();
    }
  }, [initialized]);

  return (
    <div className="App">
      <main>
        {status === 'loading' ? (
          <Loader />
        ) : (
          <>
            {status === 'unauthorized' && (
              <Warning text="Acesso não autorizado." />
            )}

            {status === 'error' && (
              <Warning text="Desculpe, um erro ocorreu. Por favor, contate o suporte." />
            )}
          </>
        )}
      </main>
    </div>
  );
};
