import ReactLoader from 'react-loader-spinner';

import styles from './styles.module.css';

export const Loader = () => {
  return (
    <div className={styles.container}>
      <ReactLoader
        type="TailSpin"
        color="#A3A3A3"
        height={70}
        width={70}
        timeout={3000}
      />
    </div>
  );
};
