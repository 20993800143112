import { BrowserRouter, Route } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import { Zoom } from './screens/Zoom';

import { client, config } from './services/keycloak';

export const App = () => {
  return (
    <ReactKeycloakProvider authClient={client} initOptions={config}>
      <BrowserRouter>
        <Route path="/:eventId/activities/:activityId" component={Zoom} />
      </BrowserRouter>
    </ReactKeycloakProvider>
  );
};
