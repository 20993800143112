import Keycloak from 'keycloak-js';

const keycloakCredentials = JSON.parse(process.env.REACT_APP_KEYCLOAK);

export const client = Keycloak({
  url: keycloakCredentials['auth-server-url'],
  realm: keycloakCredentials.realm,
  clientId: keycloakCredentials.resource,
});

export const config = {
  onLoad: 'check-sso',
};
