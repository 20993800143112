import axios from 'axios';

import { client } from '../keycloak';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const addToken = (config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${client.token}`;
};

api.interceptors.request.use((config) => {
  if (client?.token) {
    addToken(config);
    return config;
  }
  return new Promise((resolve, reject) => {
    client.onAuthSuccess = () => {
      addToken(config);
      resolve(config);
    };
    client.onAuthError = () => {
      reject(config);
    };
  });
});
