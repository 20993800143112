import { api } from './index';

export const getMe = () => {
  return api.request({
    method: 'get',
    url: 'users/me',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
