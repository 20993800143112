import PropTypes from 'prop-types';

import styles from './styles.module.css';

import warningIcon from '../../assets/svgs/warning.svg';

export const Warning = ({ text }) => {
  return (
    <div className={styles.container}>
      <img src={warningIcon} alt="Erro" className={styles.image} />
      <p>{text}</p>
    </div>
  );
};

Warning.propTypes = {
  text: PropTypes.string.isRequired,
};
